import { Box, Typography, makeStyles } from "@material-ui/core"
import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout/layout-home"
import { WorkPageContent } from "../components/works/work-page-content"
import { Parallax } from "react-parallax"
import Fade from "react-reveal/Fade"
import Truncate from "react-truncate"

//import { navigate } from "@reach/router"

const useStyles = makeStyles(theme => ({
  container: {
    position: "relative",
    paddingTop: "120px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "100px",
    },
  },
  contentContainer: {
    position: "absolute",
    top: "60px",
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      top: "40px",
    },
  },
  image: {
    height: "50vh",
    width: "100vw",
    filter: "brightness(0.5)",
    color: "#0003",
    zIndex: "999",
    [theme.breakpoints.down("sm")]: {
      height: "40vh",
    },
  },
  title: {
    margin: "0px 24px 16px 24px",
    filter: "drop-shadow(0px 0px 8px #000b)",
    maxWidth: "1400px",
    color: "#fff",
    textAlign: "center",
    fontSize: "48px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
      marginLeft: "24px",
      marginRight: "24px",
    },
  },
  catgoryText: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
}))

export default function WorkPage({ data }) {
  const { name, type, cover, content } = data.strapi.work
  const classes = useStyles()

  /*
  const goBack = () => {
    navigate(-1)
  }
  */

  return (
    <Layout>
      <Box className={classes.container}>
        <Parallax
          bgImage={cover.url}
          strength={500}
          style={{ filter: "brightness(0.3)" }}
        >
          <Box className={classes.image} />
        </Parallax>
        <Box className={classes.contentContainer}>
          <Truncate
            lines={3}
            ellipsis={<span>...</span>}
            className={classes.title}
          >
            {name}
          </Truncate>
          <Typography
            variant="h3"
            color="primary"
            className={classes.catgoryText}
          >
            {type}
          </Typography>
        </Box>
      </Box>
      {/* 
        <Box mb={5}>
          <a style={{ textDecoration: "none" }} href="#goback" onClick={goBack}>
            <Typography variant="overline">{"< back to works"}</Typography>
          </a>
        </Box>
        */}
      <Box mb={5} mx="auto" px={4} maxWidth="1400px">
        {content && <WorkPageContent data={content} />}
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query WorkPageQuery($id: ID!) {
    strapi {
      work(id: $id) {
        name
        type
        cover {
          url
          imageFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        content {
          title
          description
          video
          infos {
            name
            value
          }
          pictures {
            url
            imageFile {
              childImageSharp {
                fluid(maxWidth: 1500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
